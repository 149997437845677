<template>
  <div>
    <b-card no-body class="mb-0 p-2">
      <b-button @click="runFtpDataFetch" variant="primary"
        >Zaženi FTP pobiranje podatkov</b-button
      >
      <b-button class="mt-2 primary" variant="primary" @click="updateAlarms"
        >Posodobitev alarmov</b-button
      >
    </b-card>
  </div>
</template>
<script>
import { BButton, BCard } from 'bootstrap-vue';
import { dangerToast, successToast } from '@/utils/toast';
export default {
  components: {
    BButton,
    BCard,
  },
  methods: {
    runFtpDataFetch() {
      this.$store
        .dispatch('ftp/RUN_FTP_DATA_FETCH')
        .then(() => {
          successToast(
            this.$toast,
            this.$t('success'),
            'Ftp datoteke uspešno prenesene!',
          );
        })
        .catch(e => {
          dangerToast(
            this.$toast,
            this.$t('error'),
            'Napaka med prenašanjem ftp datotek!',
          );
        });
    },
    updateAlarms() {
      this.$store
        .dispatch('devices/RUN_ALARM_UPDATE')
        .then(() => {
          successToast(
            this.$toast,
            this.$t('success'),
            'Alarmi uspešno posodobljeni!',
          );
        })
        .catch(e => {
          dangerToast(
            this.$toast,
            this.$t('error'),
            'Napaka pri posodobitvi alarmov!',
          );
        });
    },
  },
};
</script>
<style lang=""></style>
